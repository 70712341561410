import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AfostoInstantSearchWidget } from '@afosto/instant-search-widget';
import '@afosto/instant-search-widget/dist/afosto-instant-search-widget.min.css';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Layout from '../../../components/Layout';
import Grid from '../../../components/Grid';
import Typography from '../../../components/Typography';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';

const localizations = [
  {
    locale: 'nl-NL',
    slug: 'nl/apps/instant-search/widget/',
  },
];

const demo = ({ location }) => {
  useEffect(() => {
    AfostoInstantSearchWidget.init('movies-demo');
  }, []);

  return (
    <Layout location={location} locale="en" localizations={localizations} slug="/apps/instant-search/widget/">
      <Helmet
        title="Instant Search Widget | Afosto"
        meta={[
          {
            name: 'description',
            content: 'Improve your search with the easy to integrate Instant Search Widget.',
          },
        ]}
      >
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@afosto/instant-search-widget@latest/dist/afosto-instant-search-widget.min.css"
        />
        <html lang="en" />
      </Helmet>
      <Breadcrumbs location={location} locale="en" className="mt-24" wrapper={props => <Grid container {...props} />} />
      <Grid container className="pt-16 pb-120 d-flex flex-column align-items-center">
        <Typography variant="h1" className="mb-16 mt-32" align="center">
          Instant Search Widget
        </Typography>
        <Typography
          variant="subtitle-1"
          component="h2"
          weight="normal"
          className="mb-56 mt-16"
          align="center"
          color="text-secondary"
        >
          Click the button below to open the search widget.
        </Typography>
        <Button data-af-instant-search size="lg">
          Start searching
        </Button>

        <Typography variant="h2" className="mb-16 mt-120" align="center" color="text-primary">
          How do you integrate the widget?
        </Typography>
        <Typography
          variant="body-medium"
          component="h2"
          weight="normal"
          className="mb-56 mt-16"
          align="center"
          color="text-secondary"
        >
          The widget is open source and you can check the code and documentation on the GitHub page.
          <br /> Contributions to improve the widget will also be greatly appreciated.
        </Typography>
        <Button
          color="afosto-dark"
          href="https://github.com/afosto/instant-search-widget"
          target="_blank"
          rel="noopener"
        >
          Open GitHub <Icon icon={faGithub} className="ml-8" />
        </Button>
      </Grid>
    </Layout>
  );
};

export default demo;
