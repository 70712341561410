import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import getBreadcrumbs from '../../utils/getBreadcrumbs';

const Breadcrumbs = ({ location, wrapper, start, locale, filter, ...rest }) => {
  const items = getBreadcrumbs(location, start);
  const filteredItems = items
    .filter(item => item.href !== `/${locale.split('-')[0]}/`)
    .filter(item => !filter.includes(item.label));
  const Wrapper = wrapper;

  if (filteredItems.length < 2) {
    return null;
  }

  return (
    <Wrapper>
      <nav aria-label="breadcrumb" {...rest}>
        <ol className="breadcrumb bg-transparent mb-0 p-0">
          {filteredItems.map((item, idx) => {
            if (idx + 1 === filteredItems.length) {
              return (
                <li className="breadcrumb-item active" key={item.href}>
                  {item.label}
                </li>
              );
            }
            let { href } = item;
            if (href === '/' && locale !== 'en') {
              href = `/${locale.split('-')[0]}/`;
            }

            return (
              <li className="breadcrumb-item active" aria-current="page" key={href}>
                <Link to={href}>{item.label}</Link>
              </li>
            );
          })}
        </ol>
      </nav>
    </Wrapper>
  );
};

Breadcrumbs.propTypes = {
  location: PropTypes.object,
  wrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  start: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  location: {},
  wrapper: 'div',
  start: undefined,
  filter: [],
  locale: 'en',
};

export default Breadcrumbs;
