import capitalize from './capitalize';
import deDasherize from './deDasherize';

export default (location, start) => {
  const { pathname } = location || {};

  const rawParts = pathname.split('/');
  const parts = rawParts.slice(start ? rawParts.indexOf(start) : 0);
  if (pathname.endsWith('/')) {
    parts.pop();
  }

  return parts.reduce(
    (acc, part, idx) => [
      ...acc,
      {
        label: deDasherize(capitalize(part)) || 'Home',
        href: `/${parts
          .slice(0, idx + 1)
          .filter(piece => piece !== '')
          .join('/')}/`.replace('//', '/'),
      },
    ],
    [],
  );
};
